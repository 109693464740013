import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  AbstractControl,
  FormControl,
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  AppConfigService,
  CustomValidators,
  DictionaryBaseDto,
  FormDialogComponent,
  GrowlService,
  InquiryService,
  LoggedUserService,
  OfferService,
  PolicyCreationDataDto,
  PolicyInquiryOfferDto,
  PolicyInquiryOfferEnvelopeDto,
  RouterService,
  StringUtils,
  TemplateService,
  TemplateSimpleDto,
  UserGroupBaseDto,
  UserService,
  VoteDto,
} from '../../bonding_shared';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {Observable, Subscription} from 'rxjs';
import {
  BusinessObjectType,
  DictionaryProfile,
  DocumentType,
  FieldValueType,
  InquiryProductType,
  InquiryProductTypes,
  LimitedLiability,
  PolicyElementaryRight,
  PolicyInquiryOfferStatus,
  PolicyInquiryOfferVotingStatus,
} from '../../bonding_shared/model/dictionary-ids';
import {map, tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {votingGroupFilter} from './domain/offer-allowance';
import {PolicyContractViewSection, PolicyContractViewService} from '../policy/services/policy-contract-view.service';
import {getInquiryVersion} from './domain/offer-common';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'offer-details',
  templateUrl: 'offer-details.component.html',
  styles: [
    '.vote-users {display: inline-block; padding-left: 0; width: calc(100% - 220px);}',
    '.vote-users li {display: inline-block; padding-right: 25px;}',
    '.user-comment {font-style: italic; display: inline-block; margin-bottom: 1.2em;}',
    '.user-name {font-size: 1.2em; display:block}',
  ],
})
export class OfferDetailsComponent extends DetailsView implements OnInit, OnDestroy {
  public pending: boolean;
  readonly PolicyElementaryRight = PolicyElementaryRight;
  readonly DictionaryProfile = DictionaryProfile;

  offerEnvelope: PolicyInquiryOfferEnvelopeDto;
  voteCommentControl: UntypedFormControl;
  public inquiryId: number;
  public offerId: number;
  private offerTypeId: number;
  private sourceOfferId: number;
  private blogOffer = false;
  private paramSubs: Subscription;
  templates: TemplateSimpleDto[] = [];
  userGroups: Observable<UserGroupBaseDto[]>;
  readonly viewSection = PolicyContractViewSection;

  public mehibInsuranceCoverAllowedValues = [75, 80, 85, 90, 95, 99];
  public mehibAcquisitionDiscountAllowedValues = [undefined, 5, 10];

  public bonusMalusSectionLabelKey: string;

  @ViewChild(FormDialogComponent, {static: true}) votingDialog: FormDialogComponent;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    public routerService: RouterService,
    private inquiryService: InquiryService,
    protected translateService: TranslateService,
    public router: RouterService,
    protected growlService: GrowlService,
    protected loggedUserService: LoggedUserService,
    protected templateService: TemplateService,
    protected userService: UserService,
    public offerService: OfferService,
    public appConfig: AppConfigService,
    private policyContractViewService: PolicyContractViewService
  ) {
    super(growlService);

    this.hideButtons(false);
    this.deleteButton.hidden = true;
    this.newVersionButton.hidden = true;

    this.form = new UntypedFormGroup({
      gci: new UntypedFormControl(''),
      insurancePeriod: new UntypedFormControl('', Validators.required),
      invoicedPremium: new UntypedFormControl('', Validators.required),
    });

    if (!this.appConfig.mehib) {
      this.form.addControl(
        'annualLOL',
        new UntypedFormControl(
          '',
          this.form.get('globalLOL') ? CustomValidators.lessEqThanOtherField('globalLOL') : undefined
        )
      );
      this.form.addControl('premium', new UntypedFormControl(''));
      if (this.appConfig.credendo) {
        this.form.get('premium').setValidators(Validators.required);
      }
      if (this.appConfig.ecg) {
        this.form.get('premium').disable();
      }
      this.form.addControl('premiumRate', new UntypedFormControl('', Validators.max(100)));
      this.form.addControl('nql', new UntypedFormControl('', this.requiredNql()));
      this.form.get('invoicedPremium').setValidators(CustomValidators.maxValue(() => this.form.get('premium').value));
      if (this.appConfig.credendo) {
        this.form.get('invoicedPremium').addValidators(Validators.required);
      }
    }

    this.voteCommentControl = new UntypedFormControl('');
    if (this.appConfig.mehib) {
      this.form.get('gci').setValidators(Validators.required);
    }
  }

  ngOnInit() {
    this.readParams();
  }

  readParams() {
    this.paramSubs = this.route.params.subscribe((params) => {
      const offerId = +params['offerId'];
      if (offerId === this.offerId) {
        return;
      }
      if (+params['inquiryId'] > 0) {
        this.inquiryId = +params['inquiryId'];
      }
      if (offerId > 0) {
        this.offerId = offerId;
      } else {
        this.offerId = 0;
      }
      if (+params['offerTypeId'] > 0) {
        this.offerTypeId = +params['offerTypeId'];
      } else {
        this.offerTypeId = 0;
      }
      if (+params['sourceOfferId'] > 0) {
        this.sourceOfferId = +params['sourceOfferId'];
      } else {
        this.sourceOfferId = 0;
      }
      this.blogOffer = params['blogOffer'] === 'true';

      this.initOfferField();
    });
  }

  getUserGroups(): void {
    const groupFilter = votingGroupFilter(this.offerEnvelope.offer.type);

    this.userGroups = this.userService.getUserGroups().pipe(map((groups) => groups.filter(groupFilter)));
  }

  ngOnDestroy(): void {
    this.paramSubs.unsubscribe();
  }

  onSave(voteTask?: boolean) {
    this.sendOffer(() => this.inquiryService.saveOffer(this.updateModel(), voteTask));
  }

  private updateModel() {
    const formRawValue = this.form.getRawValue();
    _.assign(this.offerEnvelope.offer, formRawValue);
    return this.offerEnvelope.offer;
  }

  private sendOffer(func: () => Observable<PolicyInquiryOfferEnvelopeDto>) {
    this.voteCommentControl.setErrors(null);
    this.showErrors = true;
    if (!this.form.valid || !this.ngForm.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(!this.form.valid ? this.form : this.ngForm.form);
    } else {
      this.inProgress = true;
      func().subscribe({
        next: (offerEnvelope) => {
          this.offerEnvelope = offerEnvelope;
          this.growlService.notice('Offer saved!');
          if (offerEnvelope.offer.warnings?.length > 0) {
            this.growlService.warning(offerEnvelope.offer.warnings.map((e) => e.message).join('\n'));
          }
          this.routerService.toInquiryOfferDetails(this.inquiryId, this.offerEnvelope.offer.id);
        },
        error: (error) => this.handleServerError(error),
        complete: () => (this.inProgress = false),
      });
    }
  }

  onCancel(route?: ActivatedRoute) {
    super.onCancel();
    this.routerService.toInquiryDetails(this.getInquiry());
  }

  private initOfferField() {
    this.offerEnvelope = <PolicyInquiryOfferEnvelopeDto>{offer: <PolicyInquiryOfferDto>{votes: {}}};
    this.inProgress = true;
    if (this.offerId === 0) {
      this.inquiryService.initOffer(this.inquiryId, this.offerTypeId, this.sourceOfferId, this.blogOffer).subscribe({
        next: (offer) => this.handleGetOffer(offer),
        error: (error) => this.handleServerError(error),
        complete: () => (this.inProgress = false),
      });
    } else {
      this.inquiryService.getOffer(this.offerId).subscribe({
        next: (offer) => this.handleGetOffer(offer),
        error: (error) => this.handleServerError(error),
        complete: () => (this.inProgress = false),
      });
    }
  }

  updateControls() {
    if (!this.form.get('aad') && this.isSectionVisible(this.viewSection.OFFER__AAD, true)) {
      this.form.addControl('aad', new UntypedFormControl('', Validators.required));
    }
    if (
      !this.form.get('acquisitionDiscount') &&
      this.isSectionVisible(this.viewSection.OFFER__NO_LOSS_REFUND__ACQUISITION_DISCOUNT__PRODUCTION_RISK)
    ) {
      this.form.addControl(
        'acquisitionDiscount',
        new UntypedFormControl('', CustomValidators.allowedValues(this.mehibAcquisitionDiscountAllowedValues))
      );
    }
    if (!this.form.get('globalLOL') && !this.isSectionVisible(this.viewSection.OFFER__GLOBAL_LOL_TYPE)) {
      this.form.addControl('globalLOL', new UntypedFormControl('', Validators.required));
    }
    if (InquiryProductTypes.MEHIB_ST.includes(this.offerEnvelope.offer.type.id)) {
      this.form
        .get('insurancePeriod')
        .setValidators(Validators.compose([Validators.required, Validators.min(1), Validators.max(24)]));
    }
    if (this.offerEnvelope.offer.type.id === InquiryProductType.FOREIGN_INVESTMENT_B) {
      this.form.get('insurancePeriod').addValidators(Validators.max(36));
    }
    if (InquiryProductTypes.MEHIB_MLT.includes(this.offerEnvelope.offer.type.id) && this.form.get('globalLOL')) {
      this.form.get('globalLOL').setValidators(null);
    }
    const invoicedPremiumControl = this.form.get('invoicedPremium');
    if (InquiryProductTypes.MEHIB_MLT.includes(this.offerEnvelope.offer.type.id) && invoicedPremiumControl) {
      invoicedPremiumControl.setValidators(null);
      invoicedPremiumControl.updateValueAndValidity();
    }
    if (this.isSectionVisible(this.viewSection.OFFER__INSURANCE_COVER_MEHIB)) {
      this.form.addControl('insuranceCover', new FormControl<number>(null, Validators.required));
    } else if (this.isSectionVisible(this.viewSection.OFFER__INSURANCE_COVER)) {
      this.form.addControl(
        'insuranceCover',
        new FormControl<number>(
          null,
          Validators.compose([Validators.required, Validators.min(0.01), Validators.max(100)])
        )
      );
    }
  }

  private handleGetOffer(envelope: PolicyInquiryOfferEnvelopeDto) {
    this.offerEnvelope = envelope;
    this.updateControls();
    this.onInquiryTypeChanged();
    this.form.patchValue(this.offerEnvelope.offer);
    if (!this.inquiryId) {
      this.inquiryId = envelope.offer.policyInquiryVersion.id;
    }
    this.afterOfferChanged();

    if (this.offerEnvelope.offer.id) {
      this.getUserGroups();
    }
    if (this.ecg) {
      this.loadTemplates();
    }
  }

  private afterOfferChanged() {
    this.serverErrors = undefined;
    this.showErrors = false;
    this.saveButton.hidden = !this.isEditable();
  }

  getInquiry() {
    const offer = this.offerEnvelope && this.offerEnvelope.offer;
    return getInquiryVersion(offer);
  }

  private isVotingStarted() {
    return this.offerEnvelope.offer.votes && this.offerEnvelope.offer.votes.length > 0;
  }

  voteDialog() {
    this.pending = false;
    this.votingDialog.open('voting.title');
  }

  vote(decision: string) {
    this.pending = true;
    this.voteCommentControl.setValue(this.voteCommentControl.value.trim());
    const voteComment = this.voteCommentControl.value;

    if (decision === 'down' && !voteComment) {
      const errors: {[key: string]: any} = {required: true};
      this.voteCommentControl.setErrors(errors);
      return;
    }

    const vote: VoteDto = <VoteDto>{};
    vote.approve = decision === 'up';
    vote.comment = voteComment;

    this.inProgress = true;
    this.inquiryService
      .voteOffer(this.offerEnvelope.offer.id, vote)
      .pipe(tap(() => this.votingDialog.hide()))
      .subscribe(
        (offer) => this.offerChangedByOperation(offer, 'Vote saved!'),
        (error) => this.handleServerError(error),
        () => (this.inProgress = false)
      );
  }

  private offerChangedByOperation(offer, msg) {
    this.offerEnvelope.binding = this.computeBinding(offer);
    this.offerEnvelope.offer = offer;
    this.growlService.notice(msg);
    this.afterOfferChanged();
  }

  // After voting envelop is not refreshed. Field binding is calculated based on previo
  private computeBinding(offer) {
    if (this.offerEnvelope.binding === true || this.offerEnvelope.binding === false) {
      return this.offerEnvelope.binding;
    }

    const prevStatusId = this.offerEnvelope.offer.status.id;
    const isBState = (statusId) =>
      statusId === PolicyInquiryOfferStatus.BINDING || statusId === PolicyInquiryOfferStatus.NOT_BINDING;

    if (isBState(prevStatusId) && !isBState(offer.status.id)) {
      return prevStatusId === PolicyInquiryOfferStatus.BINDING;
    }
    return null;
  }

  canVote() {
    const hasRight =
      (this.offerEnvelope.offer.edge &&
        this.loggedUserService.hasRight(PolicyElementaryRight.POLICY_INQUIRY_EDGE_OFFER_DECISION)) ||
      (!this.offerEnvelope.offer.edge &&
        this.loggedUserService.hasRight(PolicyElementaryRight.POLICY_INQUIRY_CLIENT_OFFER_DECISION));
    return this.isStateToVote() && this.offerEnvelope.hasRightToVote && hasRight;
  }

  private isStateToVote() {
    return (
      this.offerEnvelope &&
      this.offerEnvelope.offer &&
      this.offerEnvelope.offer.votingStatus &&
      this.offerEnvelope.offer.votingStatus.id === PolicyInquiryOfferVotingStatus.PENDING &&
      !this.offerEnvelope.offer.policyContractVersion
    );
  }

  canSave() {
    return this.isEditable((offer) => !offer.blogOfferSource);
  }

  canSaveBlog() {
    return this.isEditable();
  }

  canChangeState() {
    const oe = this.offerEnvelope;
    return (
      oe &&
      oe.offer &&
      oe.offer.id &&
      !(
        oe.offer.blogOfferSource &&
        (oe.offer.status.id === PolicyInquiryOfferStatus.BINDING ||
          oe.offer.status.id === PolicyInquiryOfferStatus.NOT_BINDING)
      )
    );
  }

  public isEditable(extraCondition?: (o: PolicyInquiryOfferDto) => boolean) {
    const oe = this.offerEnvelope;
    return (
      oe &&
      oe.offer &&
      !this.isVotingStarted() &&
      oe.offer.votingStatus &&
      oe.offer.votingStatus.id !== PolicyInquiryOfferVotingStatus.ACCEPTED &&
      oe.offer.votingStatus.id !== PolicyInquiryOfferVotingStatus.REJECTED &&
      oe.offer.votingStatus.id !== PolicyInquiryOfferVotingStatus.POLICY_CREATED &&
      oe.offer.status.id !== PolicyInquiryOfferStatus.CLOSED &&
      (!extraCondition || extraCondition(oe.offer))
    );
  }

  createRightFunc(o: PolicyInquiryOfferDto): string {
    if (o) {
      return o.edge
        ? PolicyElementaryRight.POLICY_INQUIRY_EDGE_OFFER_CREATE
        : PolicyElementaryRight.POLICY_INQUIRY_CLIENT_OFFER_CREATE;
    }
    return undefined;
  }

  updateRightFunc(o: PolicyInquiryOfferDto): string {
    if (o) {
      return o.edge
        ? PolicyElementaryRight.POLICY_INQUIRY_EDGE_OFFER_UPDATE
        : PolicyElementaryRight.POLICY_INQUIRY_CLIENT_OFFER_UPDATE;
    }
    return undefined;
  }

  canCreatePolicy() {
    return (
      this.offerEnvelope &&
      this.offerEnvelope.offer &&
      this.offerEnvelope.offer.votingStatus &&
      this.offerEnvelope.offer.votingStatus.id === PolicyInquiryOfferVotingStatus.ACCEPTED &&
      this.offerEnvelope.offer.status &&
      this.offerEnvelope.offer.status.id === PolicyInquiryOfferStatus.ACCEPTED &&
      !this.offerEnvelope.offer.policyContractVersion &&
      !this.offerEnvelope.createdPolicy &&
      this.loggedUserService.hasRight(PolicyElementaryRight.POLICY_CREATE)
    );
  }

  policyCreationDataVisible() {
    return (
      this.offerEnvelope &&
      this.offerEnvelope.offer &&
      this.offerEnvelope.offer.votingStatus &&
      (this.offerEnvelope.offer.votingStatus.id === PolicyInquiryOfferVotingStatus.ACCEPTED ||
        this.offerEnvelope.offer.votingStatus.id === PolicyInquiryOfferVotingStatus.REJECTED ||
        this.offerEnvelope.offer.votingStatus.id === PolicyInquiryOfferVotingStatus.POLICY_CREATED)
    );
  }

  createPolicy() {
    this.inProgress = true;
    const offer = <PolicyCreationDataDto>{
      id: this.offerEnvelope.offer.id,
      questionnaireDate: this.offerEnvelope.offer.questionnaireDate,
    };
    this.inquiryService.createPolicyFromOffer(offer).subscribe({
      next: (creationInfo) => this.routerService.toPolicyContractPreview(creationInfo.id),
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
  }

  grcChanged(val: boolean) {
    if (!val) {
      this.offerEnvelope.offer.grcDate = undefined;
    }
  }

  sendToGroup(userGroup): void {
    this.offerEnvelope.offer.voting.userGroup = userGroup;
    this.onSave(true);
  }

  votingStatusAccepted(): boolean {
    return (
      this.offerEnvelope.offer.votingStatus &&
      (this.offerEnvelope.offer.votingStatus.id === PolicyInquiryOfferVotingStatus.ACCEPTED ||
        this.offerEnvelope.offer.votingStatus.id === PolicyInquiryOfferVotingStatus.POLICY_CREATED)
    );
  }

  votingStatusRejected(): boolean {
    return (
      this.offerEnvelope.offer.votingStatus &&
      this.offerEnvelope.offer.votingStatus.id === PolicyInquiryOfferVotingStatus.REJECTED
    );
  }

  onLifecycle() {
    this.sendOffer(() => this.inquiryService.saveOffer(this.updateModel(), false));
  }

  canBlogOffer() {
    return (
      this.isSectionVisible(this.viewSection.OFFER__BLOG_OFFER, true) &&
      this.offerEnvelope &&
      this.offerEnvelope.offer &&
      this.offerEnvelope.offer.status &&
      (this.offerEnvelope.offer.status.id === PolicyInquiryOfferStatus.ACCEPTED ||
        this.offerEnvelope.offer.status.id === PolicyInquiryOfferStatus.ACCEPTED_BY_CLIENT)
    );
  }

  createBlogOffer() {
    const offer = this.offerEnvelope.offer;
    const inq = offer.policyInquiryVersion;
    this.routerService.toNewInquiryOfferDetails(inq.id, inq.productType.id, offer.id, true);
  }

  canCloseVoting() {
    const hasRight =
      (this.offerEnvelope.offer.edge &&
        this.loggedUserService.hasRight(PolicyElementaryRight.POLICY_INQUIRY_EDGE_OFFER_DECISION)) ||
      (!this.offerEnvelope.offer.edge &&
        this.loggedUserService.hasRight(PolicyElementaryRight.POLICY_INQUIRY_CLIENT_OFFER_DECISION));
    return this.isStateToVote() && this.offerEnvelope.hasRightToCloseVoting && this.existsVotes() && hasRight;
  }

  private existsVotes() {
    const env = this.offerEnvelope;
    return env && env.offer && env.offer.votes && env.offer.votes.length > 0;
  }

  closeVoting() {
    this.inProgress = true;
    this.inquiryService.closeVoting(this.offerEnvelope.offer.id).subscribe({
      next: (offer) => this.offerChangedByOperation(offer, 'Voting closed!'),
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
  }

  goToOffer(offerId: number) {
    this.routerService.toInquiryOfferDetails(this.inquiryId, offerId);
  }

  filteredVotes(approve: boolean): VoteDto[] {
    if (this.offerEnvelope && this.offerEnvelope.offer && Array.isArray(this.offerEnvelope.offer.votes)) {
      return this.offerEnvelope.offer.votes.filter((v) => v.approve === approve);
    }
    return [];
  }

  isSectionVisible(viewSection: PolicyContractViewSection, defaultVisibility = false) {
    if (this.offerEnvelope && this.offerEnvelope.offer && this.offerEnvelope.offer.type) {
      return this.policyContractViewService.isSectionVisible(
        this.offerEnvelope.offer.type.id,
        viewSection,
        defaultVisibility
      );
    }
    return false;
  }

  requiredNql(): (control: AbstractControl) => ValidationErrors | null {
    return (control) => {
      if (this.isSectionVisible(this.viewSection.OFFER__NQL)) {
        return Validators.required(control);
      }
      return null;
    };
  }

  calculatorDisabled() {
    return this.inProgress || !this.offerId;
  }

  goToCalculator() {
    this.routerService.toEcgCalculator(this.inquiryId, this.offerId);
  }

  onLimitedLiabilityChange(limitedLiability: DictionaryBaseDto) {
    switch (limitedLiability?.id) {
      case LimitedLiability.PX20:
        this.offerEnvelope.offer.limitedLiabilityDiscount = 2.5;
        break;
      case LimitedLiability.PX15:
        this.offerEnvelope.offer.limitedLiabilityDiscount = 5;
        break;
      case LimitedLiability.PX10:
        this.offerEnvelope.offer.limitedLiabilityDiscount = 10;
        break;
      default:
        this.offerEnvelope.offer.limitedLiabilityDiscount = null;
        break;
    }
  }

  getVotingLabel() {
    return (
      this.offerEnvelope.offer.voting.userGroup?.name +
      ' ' +
      this.translateService.instant('offer.voting.votingStartDate')
    );
  }

  getOfferTypeLabel() {
    return this.translateService.instant('offer.offerType') + ': ' + this.offerEnvelope.offer.type?.name;
  }

  globalLOLAmount(): boolean {
    return (
      this.offerEnvelope.offer.globalLOLType && this.offerEnvelope.offer.globalLOLType.id === FieldValueType.AMOUNT
    );
  }

  globalLOLTypeChanged() {
    if (this.globalLOLAmount()) {
      this.offerEnvelope.offer.globalLOLMultiplicity = undefined;
    } else {
      this.offerEnvelope.offer.globalLOL = undefined;
      this.offerEnvelope.offer.globalLOLCurrency = undefined;
    }
  }

  loadTemplates() {
    const selectors: DictionaryBaseDto[] = [this.offerEnvelope.offer.type];
    this.templateService
      .findByType(DocumentType.POLICY_OFFER, BusinessObjectType.OFFER, null, selectors, null, null, null, null, false)
      .subscribe((result) => {
        if (result !== undefined && result !== null) {
          result.forEach((r) => this.templates.push(r));
        }
      });
  }

  onPrintSelected(template: TemplateSimpleDto) {
    this.router.toDocumentDetailsNew(template.id, this.offerId);
  }

  get ecg(): boolean {
    return this.appConfig.ecg;
  }

  get newDocumentsHidden(): boolean {
    return !(this.ecg || this.offerEnvelope.offer.status?.id === PolicyInquiryOfferStatus.ACCEPTED);
  }

  discretionaryCoverChanged(val: boolean) {
    if (!val) {
      this.offerEnvelope.offer.dcMaxPaymentTermDays = undefined;
      this.offerEnvelope.offer.dcMaxLimit = undefined;
      this.offerEnvelope.offer.dcInsuranceCoverPercent = undefined;
      this.offerEnvelope.offer.dcInsuredAmount = undefined;
    }
  }

  showSendToButton() {
    const userGroupCond = !this.offerEnvelope.offer.voting?.userGroup;
    if (this.ecg) {
      return userGroupCond && this.offerEnvelope.offer.status?.id !== PolicyInquiryOfferStatus.NOT_BINDING;
    }
    return userGroupCond;
  }

  onInquiryTypeChanged() {
    if (this.offerEnvelope?.offer?.type?.id === InquiryProductType.ECG_TOP_UP) {
      this.bonusMalusSectionLabelKey = 'offer.details.discretionaryCoverBonusMalus.bonusMalusTitle';
    } else {
      this.bonusMalusSectionLabelKey = 'offer.details.discretionaryCoverBonusMalus.title';
    }
  }

  public allowedValuesLabel(values: any[]) {
    const nullLabel = 'null';
    return values.map((v) => (v ? v : nullLabel)).join(', ');
  }
}
