import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {
  BondingContractRateDto,
  BondingContractRateValueDto,
  BusinessUnitIdDto,
  DictionaryBaseDto,
  GrowlService,
} from '../../../../bonding_shared';
import {BusinessUnit} from '../../../../bonding_shared/model/business-unit';
import {BondingElementaryRight, BondType, Rating} from '../../../../bonding_shared/model/dictionary-ids';
import {BondingContractRatesService} from '../../../../bonding_shared/services/bonding-contract-rates.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'bonding-contract-rates-table',
  templateUrl: './bonding-contract-rates-table.component.pug',
})
export class BondingContractRatesTableComponent extends DetailsView implements OnInit {
  readonly BondingElementaryRight = BondingElementaryRight;
  readonly hiddenRatingIds = new Set<number>([
    Rating.KUKE_NC,
    Rating.KUKE_1,
    Rating.KUKE_2,
    Rating.KUKE_3,
    Rating.KUKE_4,
    Rating.KUKE_5,
    Rating.KUKE_6,
    Rating.KUKE_7,
    Rating.KUKE_8,
    Rating.KUKE_9,
    Rating.KUKE_10,
    Rating.KUKE_NR,
    Rating.KUKE_UNQUALIFIED,
    Rating.CC0,
    Rating.CC1,
    Rating.CC2,
    Rating.CC3,
    Rating.CC4,
    Rating.CC5,
  ]);
  readonly hiddenBondTypeIds = new Set<number>([
    BondType.REGWARANCJA_WADIUM_ON_LINE,
    BondType.REGWARANCJA_NALEZYTE_WYKONANIE_KONTRAKTU,
    BondType.REGWARANCJA_USUNIECIA_WAD_I_USTEREK,
    BondType.REGWARANCJA_NALEZYTE_WYKONANIE_KONTRAKTU_I_USUNIECIE_WAD_I_USTEREK,
    BondType.REGWARANCJA_CELNA,
    BondType.REGWARANCJA_ZWROT_ZALICZKI,
    BondType.REGWARANCJA_PLATNICZA,
    BondType.PROMESA_WADIUM,
    BondType.PROMESA_WADIUM_ON_LINE,
    BondType.PROMESA_NALEZYTE_WYKONANIE_KONTRAKTU,
    BondType.PROMESA_USUNIECIA_WAD_I_USTEREK,
    BondType.PROMESA_NALEZYTE_WYKONANIE_KONTRAKTU_I_USUNIECIE_WAD_I_USTEREK,
    BondType.PROMESA_CELNA,
    BondType.PROMESA_ZWROT_ZALICZKI,
    BondType.REGWARANCJA_KOSZTY_USUNIECIA_ODPADOW,
    BondType.REGWARANCJA_ZARZADZANIE_SKLADOWISKIEM_ODPADOW,
    BondType.REGWARANCJA_SZKODY_SRODOWISKOWE,
    BondType.REGWARANCJA_MIEDZYNARODOWE_PRZEMIESZCZANIE_ODPADOW,
    BondType.PROMESA_KOSZTY_USUNIECIA_ODPADOW,
    BondType.PROMESA_ZARZADZANIE_SKLADOWISKIEM_ODPADOW,
    BondType.PROMESA_SZKODY_SRODOWISKOWE,
    BondType.PROMESA_MIEDZYNARODOWE_PRZEMIESZCZANIE_ODPADOW,
    BondType.REGWARANCJA_WADIUM,
    BondType.CELNA,
    BondType.PLATNICZA,
    BondType.PROMESA_PLATNICZA,
  ]);
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  businessUnitId: BusinessUnitIdDto;
  bondType: DictionaryBaseDto;
  rating: DictionaryBaseDto;
  ratesDefinition: BondingContractRateDto;
  valuesWithBoundaries: BondingContractRateValueWithMaxBoundariesDto[];
  private readonly step = 0.01;

  constructor(private bondingContractRatesService: BondingContractRatesService, growlService: GrowlService) {
    super(growlService);
    this.objectNotFound = false;
  }

  ngOnInit() {
    this.form = this.ngForm.form;
  }

  onCancel(): void {
    this.loadRates();
    this.clearErrors();
  }

  onSave(): void {
    if (this.formValidates()) {
      this.ratesDefinition.values = this.valuesWithBoundaries;
      this.bondingContractRatesService.save(this.ratesDefinition).subscribe({
        next: (rates) => {
          this.ratesDefinition = rates;
          this.valuesWithBoundaries = this.calculateBoundaries(rates.values);
          this.showSavedMsg();
          this.clearErrors();
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }

  businessUnitChanged(bu: BusinessUnitIdDto) {
    this.businessUnitId = bu;
    this.bondType = null;
    this.rating = null;
    this.ratesDefinition = undefined;
  }

  bondTypeChanged(bondType: DictionaryBaseDto) {
    this.bondType = bondType;
    this.loadRates();
  }

  ratingChanged(rating: DictionaryBaseDto) {
    this.rating = rating;
    this.loadRates();
  }

  loadRates() {
    this.bondingContractRatesService
      .findRates(this.businessUnitId?.id, this.bondType?.id, this.rating?.id)
      .subscribe((rates) => {
        this.ratesDefinition = rates;
        this.valuesWithBoundaries = this.calculateBoundaries(rates.values);
        this.saveButton.hidden = false;
        this.cancelButton.hidden = false;
      });
  }

  onAddRate(rate: BondingContractRateValueWithMaxBoundariesDto) {
    rate.rate = 0;
    rate.minPremium = 0;
    rate.minAnexPremium = 0;
    rate.maxPeriodInMonths = 0;
    this.refreshValuesWithBoundaries();
  }

  minLimit(rateValues: BondingContractRateValueWithMaxBoundariesDto[]) {
    return (rate: BondingContractRateValueWithMaxBoundariesDto) => {
      const rateIndex = rateValues.indexOf(rate);
      if (rateIndex === 0) {
        return 0;
      }
      const previousRate = rateValues[rateIndex - 1];
      return previousRate.minLimitAmount + this.step;
    };
  }

  get isBondTypeSelectionRequired() {
    return this.businessUnitId && this.businessUnitId?.id !== BusinessUnit.KUKE_GSP;
  }

  get isRatingSelectionRequired() {
    return this.businessUnitId?.id === BusinessUnit.KUKE_GSP;
  }

  private refreshValuesWithBoundaries() {
    if (!this.valuesWithBoundaries) {
      return;
    }
    this.valuesWithBoundaries = this.calculateBoundaries(this.valuesWithBoundaries);
  }

  private calculateBoundaries(values: BondingContractRateValueDto[]): BondingContractRateValueWithMaxBoundariesDto[] {
    if (!values) {
      return;
    }
    return values.map((value, index, array) => {
      if (index === array.length - 1) {
        return {
          ...value,
          maxLimitAmount: null,
        };
      } else {
        const followingRate = array[index + 1];
        return {
          ...value,
          maxLimitAmount: followingRate.minLimitAmount ? followingRate.minLimitAmount - this.step : null,
        };
      }
    });
  }
}

export interface BondingContractRateValueWithMaxBoundariesDto extends BondingContractRateValueDto {
  maxLimitAmount: number;
}
