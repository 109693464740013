import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  GrowlService,
  LoggedUserService,
  RouterService,
  StringUtils,
  TrainingDto,
  UserDto,
  UserNamePipe,
  UserRange,
} from '../../bonding_shared';
import {NgForm} from '@angular/forms';

import {TrainingType, UserRole} from '../../bonding_shared/model/dictionary-ids';
import {ActivatedRoute, Params} from '@angular/router';
import {TrainingService} from '../../bonding_shared/services/training-service.service';

@Component({
  selector: 'training-details',
  templateUrl: 'training-details.component.html',
})
export class TrainingDetailsComponent extends DetailsView implements OnInit {
  public training: TrainingDto;
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;
  readonly employeeUserRange: UserRange = 'employees';
  loggedUserHasHRRole = this.loggedUserService.isHRRole();

  constructor(
    protected growlService: GrowlService,
    public trainingService: TrainingService,
    private route: ActivatedRoute,
    private router: RouterService,
    private loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.saveButton.hidden = !this.loggedUserHasHRRole;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const trainingId = +params['id'];
    if (trainingId === 0) {
      this.training = <TrainingDto>{};
      this.training.participants = [];
    } else {
      this.trainingService.getById<TrainingDto>(trainingId).subscribe({
        next: (v) => {
          this.training = v;
        },
        error: (error) => {
          this.handleServerError(error);
        },
      });
    }
  }

  public onSave(): void {
    this.serverErrors = undefined;
    if (!this.ngForm.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(this.ngForm.form);
      return;
    }
    this.inProgress = true;
    this.save();
  }

  private save(): void {
    const originalId = this.training.id;
    this.trainingService.save<TrainingDto>(this.training).subscribe({
      next: (v) => {
        this.training = v;
        this.afterObjectSaved(originalId, () => this.router.toTrainingDetails(v.id));
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }

  public isExternal(): boolean {
    return this.training?.type?.id === TrainingType.EXTRANET;
  }

  public isIntranet(): boolean {
    return this.training?.type?.id === TrainingType.INTRANET;
  }

  userFullName = (u: UserDto): string => {
    return new UserNamePipe().transform(u);
  };

  onUserParticipantChange(item: UserDto) {
    const length = this.training.participants.filter((u) => u.id === item.id).length;
    const newArrWithParticipants = this.training.participants.slice(0, -1);
    this.training.participants = newArrWithParticipants;
    if (length) {
      this.growlService.error('The user ' + item.name + ' is in the participants list');
    } else {
      this.training.participants.push(item);
    }
  }

  public showDeleteParticipantButton(): boolean {
    return this.loggedUserService.hasLoggedUserRole(UserRole.HR_MANAGER);
  }
}
